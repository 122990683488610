import React, {useEffect} from "react";
import pic from "../assets/pp.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';

const APropos = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div name="apropos" className="w-full h-screen bg-gradient-to-l from-[#003049] to-[#d62828] text-gray-300 dark:bg-[#21073C]">
            <div className="flex flex-col justify-center items-center w-full h-full">
                <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
                    <div className="sm:text-right pb-8 pl-4">
                        <p className="text-4xl font-bold inline border-b-4 border-[#8153d4]">
                            À Propos
                        </p>
                    </div>
                    <div></div>
                </div>
                <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
                    <div className="flex justify-center items-center" data-aos="fade-down-right" data-aos-duration="400"
                         data-aos-easing="linear">
                        <img
                            src={pic}
                            alt="Franck Thiaw-Woaye--Lapierre"
                            className="rounded-full shadow-2xl outline-offset-8 outline outline-2"
                            style={{width: "20rem", height: "20rem"}}
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <p className="text-xl font-bold pb-2 text-[#8153d4]">
                            Bienvenue sur mon portfolio !
                        </p>
                        <p className="text-sm leading-7">
                          Je m'appelle  Franck Thiaw-Woaye-Lapierre j'ai 21 ans, je suis étudiant en informatique à l'Université de
                          Saint-Denis, à La Réunion. J'ai une passion l'informatique qui a débuté au collège grâce aux
                          jeux vidéo et à l'assemblage de mon premier PC. Actuellement, je maîtrise la programmation,
                          mais je souhaite plus tard faire de l'électronique ou  DevOps, car la programmation
                          ne me passionne pas particulièrement, c'est bien, mais ce n'est pas mon but.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default APropos;
